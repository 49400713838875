.info-block {
  &__row {
    margin-top: 15px;
    &.sub-header {
      font-size: 14px;
      line-height: 13px;
      color: #5d636f;
      align-items: baseline;
      .sub-header {
        &__item {
          display: inline-block;
          margin-right: 15px;
          vertical-align: top;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .input-date {
        max-width: 100px;
      }
    }
    .select-template {
      &_icon {
        display: inline-block;
        vertical-align: middle;
      }
      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        min-width: 135px;
        .autocomplete {
          &__placeholder {
            color: #205591;
          }
        }
      }
    }
    .add-template {
      border-top: 1px solid #f3f5f8;
      padding-top: 14px;
      margin-top: 40px;
      &_icon {
        display: inline-block;
        vertical-align: middle;
      }
      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        padding-bottom: 4px;
      }
    }
  }
  &__status {
    color: #5d636f;
    border: 1px solid #b3f8b5;
    border-radius: 8px;
    font-size: 10px;
    padding: 5px 9px;
    display: inline-block;
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 18px;
    color: #343c4b;
    text-align: left;
  }
  &__avatar {
    margin-top: 12px;
  }
  &__right {
    text-align: right;
    vertical-align: middle;
    &_icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      padding-top: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__form {
    margin: 0;
    &__sub-title {
      font-size: 16px;
      color: #aeb1b7;
      text-align: center;
      margin-top: 15px;
    }
    &__collapse {
      padding: 20px 0;
      border-top: 1px solid #f3f5f8;
      border-bottom: 1px solid #f3f5f8;
      margin: 40px 0;
      &_wrap {
        display: flex;
      }
      &__title {
        font-size: 16px;
        color: #205591;
        font-weight: 400;
      }
      &__icon {
        margin-left: auto;
        cursor: pointer;
      }
    }
    &__field {
      margin-top: 25px;
    }
    .m-t-32 {
      margin-top: 32px;
    }
  }
}
