.toggle-block {
  position: relative;
  &__btn {
    text-align: right;
    &_filter {
      color: #343c4b !important;
      letter-spacing: 0.56px;
      padding: 13px 23px !important;
      &.hidden {
        visibility: hidden;
      }
      .btn {
        &-icon {
          display: inline-block;
          vertical-align: middle;
        }
        &-title {
          display: inline-block;
          vertical-align: middle;
        }
        &-count {
          position: absolute;
          top: -7px;
          right: -9px;
          display: inline-block;
          vertical-align: middle;
          background-color: #4cbc5c;
          border-radius: 50%;
          color: #fff;
          padding: 10px 6px;
          font-size: 14px;
          min-width: 20px;
          text-align: center;
          line-height: 0;
          margin-right: 0;
        }
      }
    }
    &_close {
      border-radius: 6px 6px 0 0 !important;
      z-index: 1051;
      position: absolute;
      right: 0;
      top: -48px;
      box-shadow: none !important;
      background-color: #fff !important;
      color: #5d636f;
      border-color: #fff !important;
      padding: 13px 12px !important;
      max-height: 48px;
      margin: 0 !important;
      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active,
      &:visited {
        color: #5d636f !important;
        box-shadow: none !important;
        opacity: 1;
        padding: 13px 12px !important;
      }
      .btn {
        &-title {
          display: inline-block;
          vertical-align: middle;
        }
        &-icon {
          display: inline-block;
          vertical-align: middle;
          margin-top: 4px;
          margin-left: 9px;
        }
      }
    }
  }
  &__popup {
    position: absolute;
    top: 53px;
    right: 0;
    z-index: 1451;
    background-color: #fff;
    border-radius: 6px 0 6px 6px;
    padding: 24px;
    &.hidden {
      display: none;
    }
    &__header {
      margin-bottom: 17px;
      &_title {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        color: #343c4b;
        margin-right: 10px;
        font-weight: 500;
      }
      &_count {
        display: inline-block;
        vertical-align: middle;
        background-color: #4cbc5c;
        border-radius: 50%;
        color: #fff;
        padding: 10px 6px;
        font-size: 14px;
        min-width: 20px;
        text-align: center;
        line-height: 0;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      padding-top: 24px;
      margin-top: 18px;
      &_btn {
        min-width: 118px;
      }
    }
    .filter-form {
      width: 100%;
    }
  }
  .fake-background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    background-color: rgba(50, 70, 92, 0.6);
    position: fixed;
  }
}

.create-btn {
  line-height: 1 !important;
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  &_title {
    display: inline-block;
    vertical-align: middle;
  }
}

.label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
