.departments-map {
  padding: 0 20px;

  &__info-window {
    width: 150px;
  }
}

.tabs-padding {
  padding: 0 20px;
}
