.show-password {
  &__icon {
    position: absolute;
    z-index: 1;
    display: block;
    top: 50%;
    right: 16px;
    cursor: pointer;
    margin-top: -3px;
  }
}
