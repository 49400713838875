$backgroundcolor: #f9fafc;

.expand-options {
  &__wrapper {
    display: flex;
    box-shadow: 0px 2px 4px rgba(68, 73, 80, 0.1);
    background-color: $backgroundcolor;
    border-radius: 8px;
  }
  &__options {
    display: none;
    align-items: center;
    justify-content: space-between;
    &::after {
      content: '';
      height: 16px;
      border-right: 1px solid #d7e1ec;
    }

    &_expanded {
      display: flex;
    }
  }
  &__btn {
    padding: 6px 7px !important;
    margin: 0 !important;
    display: flex;
    align-items: center;

    &_expanded {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
  &__content {
    margin-left: 8px;
  }
}
