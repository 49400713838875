.loading-container {
  width: 192px;
  display: flex;
  justify-content: center;
  line-height: 112px;
}

.option-container {
  width: 192px;
  cursor: pointer;
  transition: all 300ms;

  .text {
    padding: 0 6px;
    width: 192px;
    text-align: center;
  }
}

.option-container-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.option-container:hover {
  transform: scale(1.03);
  box-shadow: inset 0 0 0 1px #f6f6f6;
  border-radius: 10%;
  background-image: radial-gradient(
    circle,
    #ffffff,
    #fafafa,
    #f6f6f6,
    #f1f1f1,
    #ededed,
    #ededed,
    #ededed,
    #ededed,
    #f1f1f1,
    #f6f6f6,
    #fafafa,
    #ffffff
  );
}
