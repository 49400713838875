.user-info {
  background-color: transparent;
  display: flex;
  align-items: center;

  &__name-wrapper {
    margin-left: 24px;
  }

  &__name-inner-wrapper {
    font-size: 14px;
  }

  &__count-text {
    display: flex;
    align-items: center;

    .arrow-wrapper {
      width: 10px;
      fill: #fff;
      margin-left: 14px;
    }
  }

  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
