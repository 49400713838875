@import 'src/pages/Login/styles';

.session-row {
  &__token-control {
    display: inline-flex;
    & > * {
      margin-right: 5px !important;
    }
  }
}

.selected-customers-block {
  pointer-events: none;

  .error-block {
    @extend .customer-error;
    margin-bottom: 5px;
    pointer-events: none;
  }
}
