.search-input {
  position: relative;

  &::after {
    content: '';
    border-right: 1px solid #e6eaf0;
    position: absolute;
    right: 46px;
    height: 33px;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input {
    font-size: 14px !important;
    padding: 15px 50px 15px 24px !important;
    box-sizing: border-box;
    border-radius: 108px !important;
    border: 1px solid #ced4da;
    width: 100%;
    line-height: 1.5;

    &:focus {
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
  }

  &__icon {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    right: 12px;
    margin-top: 3px;
    top: 50%;
    transform: translateY(-50%);
  }
}
