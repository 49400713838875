.demand-block {
  &__row {
    margin-top: 15px;

    &.sub-header {
      font-size: 14px;
      line-height: 13px;
      color: #5d636f;
      align-items: baseline;

      .sub-header {
        &__item {
          display: inline-block;
          margin-right: 15px;
          vertical-align: top;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .input-date {
        max-width: 100px;
      }
    }

    .select-template {
      &_icon {
        display: inline-block;
        vertical-align: middle;
      }

      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        min-width: 135px;

        .autocomplete {
          &__placeholder {
            color: #205591;
          }
        }
      }
    }

    .add-template {
      border-top: 1px solid #f3f5f8;
      padding-top: 14px;
      margin-top: 40px;

      &_icon {
        display: inline-block;
        vertical-align: middle;
      }

      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        padding-bottom: 4px;
      }
    }
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #343c4b;
  }

  &__right {
    text-align: right;
    vertical-align: middle;

    &_icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      padding-top: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .order-status {
      color: #343c4b;
      font-size: 12px;
      padding: 4px 8px;
      display: inline-block;
      border: 1px solid #94d79d;
      border-radius: 38px;
    }
  }

  &__form {
    margin: -23px 0 0 0;

    &__sub-title {
      font-size: 18px;
      margin-bottom: -5px;
      font-weight: 400;
      color: #343c4b;
    }

    &__collapse {
      padding: 20px 0;
      border-top: 1px solid #f3f5f8;
      border-bottom: 1px solid #f3f5f8;
      margin: 40px 0;

      &_wrap {
        display: flex;
      }

      &__title {
        font-size: 16px;
        color: #205591;
        font-weight: 400;
      }

      &__icon {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &__field {
      margin-top: 15px;
    }

    .hidden {
      display: none;
    }
  }

  .m-t-32 {
    margin-top: 32px;
  }

  .m-t-25 {
    margin-top: 25px;
  }

  .m-t-5 {
    margin-top: 5px;
  }
}

.demand-filter {
  &__field {
    margin-bottom: 10px;
  }
}

.history-page {
  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #343c4b;
  }

  &__right {
    text-align: right;
    vertical-align: middle;

    .order-status {
      color: #343c4b;
      font-size: 12px;
      padding: 4px 8px;
      display: inline-block;
      border: 1px solid #94d79d;
      border-radius: 38px;
    }
  }

  &__tabbar {
    margin: 24px 0;
  }

  &__description {
    word-break: break-word;
  }
}
