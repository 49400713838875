.tooltip-wrap {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}

.tooltip-message {
  white-space: pre-wrap;
}
