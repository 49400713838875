/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

@import 'variable';

/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width;
  height: 100%;
  top: 0px;
  z-index: 20;
  background: $sidebar;
  font-size: 16px;

  .exchange-rates {
    text-align: left;
    position: absolute;
    bottom: 90px;
    left: 21px;

    &-row {
      margin-bottom: 8px;
      color: white;
    }

    &-header {
      color: #D6D8DB;
      font-size: 14px;
    }

    &-course-divider {
      margin: 0 6px;
    }

    &-currency-code {
      font-weight: 400;
    }

    .arrow-down {
      display: inline-block;
      margin: 0 15px;
      vertical-align: super;
      @include arrow-down($sidebar-exchange-rate-arrow);
    }
  }

  &__wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;

    .scroll-sidebar {
      flex: 0 0 70%;
    }
  }

  &__footer {
    height: 100px;

    .react-switch {
      // position: absolute;
      // bottom: 25px;
      margin-left: 21px;
      display: block;
      font-size: 14px;
    }
  }
}

.fix-sidebar .left-sidebar {
  position: fixed;
}

/*******************
use profile section
******************/

.user-profile {
  text-align: center;
  position: relative;

  .profile-img {
    width: 50px;
    margin: 0 auto;
    border-radius: 100%;

    &::before {
      -webkit-animation: 2.5s blow 0s linear infinite;
      animation: 2.5s blow 0s linear infinite;
      position: absolute;
      content: '';
      width: 50px;
      height: 50px;
      top: 0;
      margin: 0 auto;
      border-radius: 50%;
      z-index: 0;
    }

    @-webkit-keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }
    @keyframes blow {
      0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0.1);
        opacity: 0;
        -webkit-transform: scale3d(1, 1, 0.5);
        transform: scale3d(1, 1, 0.5);
      }
    }

    img {
      width: 100%;
      border-radius: 100%;
    }
  }

  .profile-text {
    padding: 5px 0;
    position: relative;

    a {
      color: $bodytext;
    }
  }

  .dropdown-menu {
    left: 0px;
    right: 0px;
    width: 180px;
    margin: 0 auto;
  }
}


/*******************
sidebar footer
******************/

.sidebar-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  transition: 0.2s ease-out;
  width: 240px;
  background: $sidebar-alt;
  border-top: 1px solid $border;

  a {
    padding: 15px;
    width: 33.333337%;
    float: left;
    text-align: center;
    font-size: 18px;
  }
}


/*******************
sidebar navigation
******************/

.sidebar-nav-popover {
  .popover {
    background: $sidebar-popover;
    width: 160px;

    .arrow::after {
      border-right-color: $sidebar-popover;
    }

    ul {
      list-style: none;
      padding: 0;

      > li > a {
        color: $white;
        padding: 14px 6px 8px 15px;
        display: block;

      }

      > li > a:hover {
        color: $sidebar-text-hover;
      }

      > li.active-item > a {
        color: $white;
        font-weight: 600;
      }

      > li.active-item > a:hover {
        color: $white;
      }
    }
  }
}

.scroll-sidebar {
  overflow: auto;
  scrollbar-color: #333 $sidebar;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;
  }
}

.collapse.in {
  display: block;
  font-size: 14px;
}

.sidebar-nav {
  background: $sidebar;

  ul {
    margin: 0px;
    padding: 0px;

    li {
      list-style: none;

      a {
        color: $white;
        padding: 8px 8px 8px 21px;
        display: grid;
        grid-template-columns: 20px auto 20px;
        align-items: center;
      }

      ul {
        padding-left: 28px;
        padding-bottom: 0;
        text-align: left;

        > li {
          font-weight: normal;
        }

        > li > a {
          padding: 0 0 8px 21px;
          display: block;
        }

        > li.active-item > a {
          font-weight: 500;
        }

        > li.active-item > a:hover {
          color: $white;
        }

        > li > a:hover {
          color: $sidebar-text-hover;
        }
      }
    }
  }

  > ul > li {
    path {
      fill: $sidebar-icon;
    }

    svg {
      vertical-align: sub;
    }

    span {
      display: inline-flex;
      margin-left: 20px;
    }

    border-left: 3px solid $sidebar;

    &:hover {
      color: $sidebar-text-hover;
      border-left: 3px solid $sidebar-border;

      path {
        fill: $sidebar-icon-hover;
      }
    }

    &.active-item {
      background: $sidebar-active;
      border-left: 3px solid $sidebar-border;

      > a {
        color: $white !important;
        font-weight: 500;
        border-left: none;
      }

      > a:hover {
        color: $white !important;
      }

      path {
        fill: $sidebar-icon-active;
      }
    }

    > a {
      border-left: none !important;
    }

    > a:hover {
      border-left: none !important;
      color: $sidebar-text-hover !important;
    }

    .label {
      float: right;
      margin-top: 6px;
    }
  }
}

.sidebar-nav .has-arrow {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    width: .4em;
    height: .4em;
    border-width: 2px 0 0 2px;
    border-style: solid;
    border-color: $sidebar-text;
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    transform: rotate(-135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    -webkit-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out;
  }
}

.sidebar-nav .active-item > .has-arrow::after,
.sidebar-nav li > .has-arrow.active-item::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(45deg) translate(0, -50%);
  -ms-transform: rotate(45deg) translate(0, -50%);
  -o-transform: rotate(45deg) translate(0, -50%);
  transform: rotate(45deg) translate(0, -50%);
}

/****************
When click on sidebar toggler and also for tablet
*****************/

.mini-sidebar .sidebar-nav #sidebarnav li {
  position: relative;

  > a {
    > span {
      display: none;
    }

    > svg {
      transform: translate(50%);
    }
  }

}

.mini-sidebar .sidebar-nav #sidebarnav > li > ul {
  position: absolute;
  left: 60px;
  top: 59px;
  width: 200px;
  z-index: 1001;
  background: $sidebar-alt;
  display: none;
  padding-left: 1px;
}

.mini-sidebar .sidebar-nav #sidebarnav > li:hover > ul {
  height: auto !important;
  overflow: auto;
}

.mini-sidebar .sidebar-nav #sidebarnav > li > .has-arrow:after {
  display: none;
}

.mini-sidebar {
  .left-sidebar {
    width: 93px;

    .exchange-rates {
      display: none;
    }

    .react-switch-second-label {
      margin-left: -5px;
    }
  }

  .user-profile {
    padding-bottom: 30px;
    width: 60px;
  }

  .hide-menu,
  .nav-small-cap,
  .sidebar-footer,
  .user-profile .profile-text {
    display: none;
  }

  .nav-devider {
    width: 60px;
  }

  .sidebar-nav {
    background: transparent;
  }

  .sidebar-nav #sidebarnav > li > a {
    width: 100%;
  }

  .sidebar-nav #sidebarnav > li:hover > a {
    background: $sidebar-active;
    color: $white;
    border-color: $themecolor;

    i {
      color: $white;
    }

    .hide-menu {
      display: inline;
    }
  }

  .sidebar-nav #sidebarnav > li.active-item > a {
    border-color: transparent;

    &.active {
      border-color: $themecolor;
    }
  }
}

@media (max-width: 767px) {
  .mobile-sidebar {
    .left-sidebar {
      left: 0;
      top: 64px;
    }
  }

  .mini-sidebar {
    .page-wrapper {
      margin-left: 93px !important;
      padding-top: 0 !important;
    }
  }
  .left-sidebar {
    left: -100%;
    top: 64px;
    width: 100%;
    transition: left 0.3s;

    .exchange-rates {
      display: none;
    }

    .scroll-sidebar {
      flex: 0 0 100% !important;
    }
  }
  .mini-sidebar {
    .page-wrapper {
      margin-left: 0 !important;
      padding-top: 0 !important;
    }
  }
}
