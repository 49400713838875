.required-mail-modal {
  background: #fff;
  border-radius: 10px;
  word-break: break-word;

  .form-wrapper {
    margin: 0;
  }

  &__no-data {
    text-align: center;
  }

  &__item {
    &__subject {
      font-size: 24px;
      color: #343c4b;
    }

    &__date {
      color: #aeb1b7;
      font-size: 12px;
      margin-top: 10px;
    }

    &__desc {
      margin-top: 10px;
      font-size: 14px;
    }

    &__footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}

.notifications {
  &-header {
    &__read-all {
      cursor: pointer;
      user-select: none;

      &:hover {
        color: #4cbc5c;
      }
    }
  }

  &-list {
    cursor: auto !important;

    > li {
      cursor: pointer;

      &:hover {
        background: rgba(148, 215, 157, 0.1);
      }

      .close-icon {
        cursor: pointer;
        user-select: none;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.8);
        }
      }
    }
  }
}

.sidebar-banner {
  width: 100%;
  display: block;
  padding: 0 21px;
  margin-top: 16px;
}

.dashboard-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-info-wrapper {
  padding: 24px 0 24px 24px;
}
