.printing-document {
  .name {
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
