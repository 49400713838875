.status {
  border-radius: 38px;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-block;

  &_color {
    &_red {
      background: #fff0f0;
      color: #e95f5a;
    }

    &_green {
      background: #edf8ef;
      color: #4ca852;
    }

    &_grey {
      background: #e8ebed;
      color: grey;
    }

    &_blue {
      background: #3171B61A;
      color: #3171B6;
    }
  }
}
