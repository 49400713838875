.warning-radio {
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  .input-main-wrapper {
    margin-top: 0;
  }

  .inp-radio-wrap {
    margin-top: 0;
  }
}

.warning-label {
  display: block;
  margin-bottom: 5px;
  color: #fb3a3a;
}

.product-logo {
  width: 35px;
  height: 35px;
  font-weight: bold;
}
