.instructions-wrapped {
  padding-left: 42px;
  padding-right: 22px;
}
.collapse-main {
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #343c4b;
  cursor: pointer;
  display: flex;

  &__row {
    display: flex;
    box-shadow: 0 1px 1px rgba(51, 56, 63, 0.16);
    height: 56px;
    align-items: center;
    width: 100%;

    &--without-shadow {
      box-shadow: none;
    }

    &__arrow {
      padding: 0 24px;
      margin-left: auto;
    }
  }
}

.additional-info {
  padding: 10px 0px;
  font-size: 14px;
}

.icon-round {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    color: #32465c;
    font-size: 12px;

    &--opened {
      color: #fff;
    }
  }

  &--grey {
    background-color: #f0f1f2;
  }

  &--darkblue {
    background-color: #32465c;
  }
}
