.rmodal .page-header {
  margin: 0;
  padding: 0;
}

.page-header {
  margin-bottom: 24px;
  padding: 24px 20px 0 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #343c4b;
    flex-basis: 70%;
  }

  &__subtitle {
    color: #aeb1b7;
  }

  &__right {
    text-align: right;
    vertical-align: middle;
    flex-shrink: 0;
  }
}
