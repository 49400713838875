@mixin withGradient {
  background: linear-gradient(to left, #fff, #ccc 50%, #fff);
  background-size: 400%;
}

@mixin withAnimation {
  -webkit-animation: animateGradient 2s ease infinite;
  -moz-animation: animateGradient 2s ease infinite;
  animation: animateGradient 2s ease infinite;
}

.placeholder {
  min-height: 8px;
  min-width: 10px;
  border-radius: 14px;

  @include withGradient();

  &_animated {
    @include withAnimation();
  }
}

@-webkit-keyframes animateGradient {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@-moz-keyframes animateGradient {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes animateGradient {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
