@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-ExtraBold.eot');
  src:
    local('Gilroy ExtraBold'),
    local('Gilroy-ExtraBold'),
    url('Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-ExtraBold.woff') format('font-woff'),
    url('Gilroy-ExtraBold.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Heavy.eot');
  src:
    local('Gilroy Heavy'),
    local('Gilroy-Heavy'),
    url('Gilroy-Heavy.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Heavy.woff') format('font-woff'),
    url('Gilroy-Heavy.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-LightItalic.eot');
  src:
    local('Gilroy Light Italic'),
    local('Gilroy-LightItalic'),
    url('Gilroy-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-LightItalic.woff') format('font-woff'),
    url('Gilroy-LightItalic.ttf') format('ttf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-ThinItalic.eot');
  src:
    local('Gilroy Thin Italic'),
    local('Gilroy-ThinItalic'),
    url('Gilroy-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-ThinItalic.woff') format('font-woff'),
    url('Gilroy-ThinItalic.ttf') format('ttf');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-BlackItalic.eot');
  src:
    local('Gilroy Black Italic'),
    local('Gilroy-BlackItalic'),
    url('Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-BlackItalic.woff') format('font-woff'),
    url('Gilroy-BlackItalic.ttf') format('ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-BoldItalic.eot');
  src:
    local('Gilroy Bold Italic'),
    local('Gilroy-BoldItalic'),
    url('Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-BoldItalic.woff') format('font-woff'),
    url('Gilroy-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-SemiBold.eot');
  src:
    local('Gilroy SemiBold'),
    local('Gilroy-SemiBold'),
    url('Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-SemiBold.woff') format('font-woff'),
    url('Gilroy-SemiBold.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-UltraLightItalic.eot');
  src:
    local('Gilroy UltraLight Italic'),
    local('Gilroy-UltraLightItalic'),
    url('Gilroy-UltraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-UltraLightItalic.woff') format('font-woff'),
    url('Gilroy-UltraLightItalic.ttf') format('ttf');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-SemiBoldItalic.eot');
  src:
    local('Gilroy SemiBold Italic'),
    local('Gilroy-SemiBoldItalic'),
    url('Gilroy-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-SemiBoldItalic.woff') format('font-woff'),
    url('Gilroy-SemiBoldItalic.ttf') format('ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Light.eot');
  src:
    local('Gilroy Light'),
    local('Gilroy-Light'),
    url('Gilroy-Light.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Light.woff') format('font-woff'),
    url('Gilroy-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-MediumItalic.eot');
  src:
    local('Gilroy Medium Italic'),
    local('Gilroy-MediumItalic'),
    url('Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-MediumItalic.woff') format('font-woff'),
    url('Gilroy-MediumItalic.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-ExtraBoldItalic.eot');
  src:
    local('Gilroy ExtraBold Italic'),
    local('Gilroy-ExtraBoldItalic'),
    url('Gilroy-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-ExtraBoldItalic.woff') format('font-woff'),
    url('Gilroy-ExtraBoldItalic.ttf') format('ttf');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Regular.eot');
  src:
    local('Gilroy Regular'),
    local('Gilroy-Regular'),
    url('Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Regular.woff') format('font-woff'),
    url('Gilroy-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-HeavyItalic.eot');
  src:
    local('Gilroy Heavy Italic'),
    local('Gilroy-HeavyItalic'),
    url('Gilroy-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-HeavyItalic.woff') format('font-woff'),
    url('Gilroy-HeavyItalic.ttf') format('ttf');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Medium.eot');
  src:
    local('Gilroy Medium'),
    local('Gilroy-Medium'),
    url('Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Medium.woff') format('font-woff'),
    url('Gilroy-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-RegularItalic.eot');
  src:
    local('Gilroy Regular Italic'),
    local('Gilroy-RegularItalic'),
    url('Gilroy-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-RegularItalic.woff') format('font-woff'),
    url('Gilroy-RegularItalic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-UltraLight.eot');
  src:
    local('Gilroy UltraLight'),
    local('Gilroy-UltraLight'),
    url('Gilroy-UltraLight.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-UltraLight.woff') format('font-woff'),
    url('Gilroy-UltraLight.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Bold.eot');
  src:
    local('Gilroy Bold'),
    local('Gilroy-Bold'),
    url('Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Bold.woff') format('font-woff'),
    url('Gilroy-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Thin.eot');
  src:
    local('Gilroy Thin'),
    local('Gilroy-Thin'),
    url('Gilroy-Thin.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Thin.woff') format('font-woff'),
    url('Gilroy-Thin.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('Gilroy-Black.eot');
  src:
    local('Gilroy Black'),
    local('Gilroy-Black'),
    url('Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('Gilroy-Black.woff') format('font-woff'),
    url('Gilroy-Black.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
}
