.profile-page {
  display: block;
  padding: 24px 0;
  &__title {
    padding: 0 24px;
    color: #32465c;
  }
  &__switch {
    margin-bottom: 20px;
    padding: 0 24px;
    .rc-tabs-nav-container {
      font-size: 16px;
    }
  }
  &__content {
    padding: 0 24px;
  }
}

.tabs-padding {
  padding: 0 20px;
}
