/*
Template Name: Monster Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
// Variables
$bodyfont: 'Gilroy', 'Rubik', sans-serif;
$headingfont: 'Gilroy', 'Rubik', sans-serif;

/*Theme Colors*/

$topbar: #fff;
$sidebar: #32465c;
$sidebar-active: #323c52;
$sidebar-white: #ffffff;
$sidebar-alt: #e8eff0;
$sidebar-popover: #323c52;
$sidebar-text: #ffffff;
$sidebar-border: #f8f8fa;
$sidebar-text-hover: #4cbc5c;
$sidebar-icons: #a6b7bf;
$sidebar-icon: #858a93;
$sidebar-icon-hover: #ffffff;
$sidebar-icon-active: #4cbc5c;
$sidebar-exchange-rate-arrow: #e95f5a;
$bodycolor: #f2f7f8;
$headingtext: #2c2b2e;
$bodytext: #54667a;
$header-company-arrow: #f8f8fa;
$header-notification: #e95f5a;

$light-text: #a6b7bf;
$themecolor: #4cbc5c;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #f62d51;
$success: #55ce63;
$warning: #ffbc34;
$primary: #4cbc5c;
$info: #009efb;
$inverse: #2f3d4a;
$muted: #90a4ae;
$dark: #263238;
$light: #f2f7f8;
$extra-light: #ebf3f5;
$secondary: #f3f5f8;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #4ab657;
$warning-dark: #e9ab2e;
$primary-dark: #4cbc5c;
$info-dark: #028ee1;
$red-dark: #d61f1f;
$inverse-dark: #232a37;
$dark-transparent: rgba(0, 0, 0, 0.05);

/*Normal Color*/
$white: #ffffff;
$black: #000000;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #01c0c8;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border: #f3f1f1;
$dark-text: #848a96;
$radius: 4px;
$form-brd: #d9d9d9;
$sidebar-width: 304px;

/*Preloader*/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
  .cssload-speeding-wheel {
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
  }
}

@mixin arrow-down($color) {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $color transparent transparent transparent;
}
