.form-wrapper {
  margin: 15px 0 0 0;
}

.form-counter {
  text-align: right;
  color: #858a93;
  font-size: 12px;
}

.form-counter_negative {
  color: #eb5757;
}

.btn {
  margin: 5px 0;
}

.btn:not(:last-child) {
  margin-right: 16px;
}

.form-label .form-control,
.form-label .form-control:focus {
  min-height: initial;
  border: none;
  box-shadow: none;
  height: initial;
  padding-left: 0;
  padding-bottom: 0;
}

input[type='checkbox'].form-input {
  width: initial;
  height: 20px;
}

.form-row {
  display: flex;
  padding: 5px 0;
}

fieldset.form-label {
  padding: 0 8px 8px 12px;
  border: 0.75px solid #cccccc;
  box-sizing: border-box;
  border-radius: 6px;
  min-height: 48px;
  margin-bottom: 10px;
}

fieldset.form-label legend {
  margin-bottom: 0;
  min-height: 15px;
  font-size: inherit;
  width: fit-content;
}

.form-header {
  font-size: 24px;
  margin: 18px 0;
}

.col-4:first-child {
  padding-left: 0;
}

.form-button {
  color: #ffffff;
  padding: 10px 30px;
  background-color: #205591;
  border-radius: 8px;
  text-transform: uppercase;
}

.DateRangePickerInput__showClearDates {
  width: 280px;
  padding-right: 10px;
}

.DateInput_input__small {
  font-size: 12px;
  padding: 0px;
  vertical-align: sub;
}

.DateRangePickerInput_clearDates {
  right: 30px;
}

.DateInput_input__focused {
  border-bottom: none;
}

.DateRangePickerInput_calendarIcon {
  padding: 0;
  padding-top: 3px;
  float: right;
  margin: 0;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid transparent;
}

.field-input-wrapper .tooltip-absolute {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-40%);
}

.server-error {
  background: #fff;
  border: 1px solid #e95f5a;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow: hidden;
}
.server-error .server-error__text {
  white-space: pre-wrap;
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 40px);
  color: #e95f5a;
  padding: 16px;
}
.server-error .server-error__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(233, 95, 90, 0.4);
  padding: 6px;
  width: 40px;
}
.server-error .server-error__icon svg path {
  fill: #e95f5a;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.sms-field {
  max-width: 114px;
  margin: 0 auto;
}

.sms-field .form-input-error {
  margin: 2px auto 0;
}
