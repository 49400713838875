.wrapper-statuses {
  position: absolute;
  right: 20px;
  max-width: 35%;
  display: flex;
  flex-direction: column;
  align-items: start;

  .wrapper-status {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;

    .label-status {
      text-align: start;
      margin-right: 5px;
    }
  }
}


