.expired-sms {
  margin-top: 16px;
  text-align: center;
  color: #858a93;

  &__title {
    display: block;
  }

  &__timer {
    display: block;
    margin-top: 2px;
  }

  &__expired {
    display: block;
    color: #eb5757;
  }
}

.resend-sms {
  color: #4cbc5c;
  text-align: center;
  margin-top: 16px;
  cursor: pointer;

  &__title {
    display: inline-block;
    vertical-align: middle;
  }

  &__timer {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
  }
}
