@import '~rc-time-picker/assets/index.css';

.rc-time-picker-panel {
  z-index: 1400 !important;
  padding-top: 46px;
}

.rc-time-picker-wrap {
  width: 100%;
}

.rc-time-picker-panel-input-wrap {
  display: none;
}

.rc-time-picker-input {
  height: 48px;
  font-size: 14px;
  padding: 16px;
  border: none;
}

.rc-time-picker-panel-select {
  font-size: 14px;
  max-width: 56px;
}

.rc-time-picker-clear {
  position: absolute;
  top: 17px;
  right: 6px;
}

.rc-time-picker-inner {
  border: none;
}

.rc-time-picker-panel-narrow {
  max-width: none;
}

.rc-time-picker-combobox {
  display: block;
}

.rc-time-picker-panel-select:first-child {
  float: left;
}

.rc-time-picker-panel-select:last-child {
  float: right;
}
