.export-button {
  cursor: pointer;
  display: inline-block;
  & > svg {
    display: inline-block;
    vertical-align: middle;
  }

  &__name {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.export-list {
  & li {
    cursor: pointer;
    &:hover {
      color: #4cbc5c;
    }
  }
}
