.error-message-container {
  padding-left: 14px;

  li {
    margin-bottom: 14px;
    word-break: break-word;
  }
}

.error-import {
  &_modal-list,
  &_modal-description {
    font-size: 16px;
  }
}

.accounts-container {
  padding-left: 14px;

  li {
    margin-bottom: 14px;
    word-break: break-word;
  }
}

.import-modal-header {
  display: flex;
  margin-bottom: 16px;
  column-gap: 14px;
}

.import-modal-title {
  font-size: 16px;
  font-weight: bold;
}

.import-modal-info {
  font-size: 16px;
}
