.action-button-popover {
  &__button {
    &__label {
      display: inline-block;
      vertical-align: middle;
    }
    &__icon {
      display: inline-block;
      vertical-align: middle;
      margin-left: 4px;
    }
  }
  &__menu {
    cursor: pointer;
    list-style: none;
    padding: 0;
    margin: 0;
    &__item {
      padding: 10px 10px 10px 10px;
      display: block;
      color: #343c4b;
      font-size: 14px;
      &:hover {
        color: #4cbc5c;
      }
    }
  }
}
