$scaler: 1.5;

%message {
  font-size: 13px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.list-regulation {
  padding-left: 15px;
}
.customer-error {
  @extend %message;
  color: #eb5757;
}

.customer-warning {
  @extend %message;
  color: #ffbd00;
}

.login-page {
  background-color: #32465c;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &__header {
    height: 72px;
    border-bottom: 1px solid;
    padding-top: 24px;
    margin-bottom: 176px;
    &_logo {
      margin-left: 128px;
      display: inline-block;
      vertical-align: middle;
      &.white {
        @media (max-width: 415px) {
          display: none;
        }
      }
      &.green {
        display: none;
        @media (max-width: 415px) {
          display: inline-block;
          width: 162.5px;
        }
      }
      @media (max-width: 1124px) {
        margin-left: 48px;
      }
      @media (max-width: 460px) {
        margin-left: 24px;
      }
    }
    &__languages {
      display: inline-block;
      vertical-align: middle;
      float: right;
      margin-right: 128px;
      &_item {
        display: inline-block;
        vertical-align: middle;
        margin-right: 17px;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.4);
        position: relative;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          color: #fff;
          &:after {
            content: '';
            position: absolute;
            width: 27px;
            height: 3px;
            top: 45px;
            left: 0;
            background: #4cbc5c;
            border-radius: 2px;
            @media (max-width: 415px) {
              content: none;
            }
          }
          @media (max-width: 415px) {
            color: #4cbc5c;
          }
        }
        @media (max-width: 415px) {
          color: #32465c;
          margin-right: 10px;
        }
      }
      @media (max-width: 1124px) {
        margin-right: 48px;
      }
      @media (max-width: 460px) {
        margin-right: 24px;
      }
    }
    @media (max-width: 1124px) {
      margin-bottom: 57px;
    }
    @media (max-width: 415px) {
      margin-bottom: 0;
      height: 56px;
      padding-top: 15px;
      background-color: #fff;
      border-bottom: 1px solid #d6d8db;
    }
  }
  &__body {
    position: absolute;
    min-height: 700px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    // padding-bottom: 100px;
    &__box {
      width: 322px * $scaler;
      margin: 0 40px 0 128px;
      &__title {
        font-size: 24px;
        font-weight: 500;
        color: #32465c;
        text-align: center;
        @media (max-width: 415px) {
          font-size: 20px;
        }
      }
      .site-link {
        color: #fff;
        font-size: 14px;
        opacity: 0.5;
        position: absolute;
        bottom: -50px;
        left: 50%;
        margin-left: -78px;
        letter-spacing: 1px;
        @media (max-width: 1124px) {
          display: none;
        }
      }
      .card-body {
        padding: 48px;
        @media (max-width: 1124px) {
          padding: 44px 56px;
        }
        @media (max-width: 415px) {
          padding: 24px 24px 5px;
        }
      }
      .login-page__body__info__suggestion {
        display: none;
        @media (max-width: 1124px) {
          position: absolute;
          bottom: -50px;
          left: 50%;
          margin-left: -78px;
          display: block;
        }
        @media (max-width: 415px) {
          bottom: -30px;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;
          color: #858a93;
          opacity: 0.6;
        }
      }
      @media (max-width: 1124px) {
        order: 1;
        margin: 0 auto;
        width: 431px;
      }
      @media (max-width: 768px) {
        width: 373px;
      }
      @media (max-width: 415px) {
        border: none !important;
        border-radius: initial !important;
        margin-top: -65px;
        width: 100%;
        margin-bottom: 0 !important;
      }
    }
    &__info {
      align-items: center;
      color: #fff;
      max-width: 480px;
      &__phone {
        display: block;
        &_title {
          display: block;
          font-size: 18px;
          @media (max-width: 415px) {
            font-size: 14px;
          }
        }
        &_desc {
          display: block;
          font-size: 12px;
        }
        @media (max-width: 415px) {
          position: absolute;
          bottom: 16px;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      &__title {
        font-size: 40px;
        line-height: 50px;
        display: block;
        margin-top: 34px;
        .strong {
          font-weight: 400;
        }
        @media (max-width: 1124px) {
          font-size: 24px;
          line-height: 28px;
          margin-top: 16px;
          margin-bottom: 58px;
        }
        @media (max-width: 415px) {
          font-size: 14px;
          color: #5d636f;
          max-width: 155px;
          line-height: 22px;
          margin-top: 24px;
          margin-bottom: 75px;
        }
      }
      &__suggestion {
        opacity: 0.5;
        margin-top: 60px;
        color: #fff;
        &_icon {
          display: inline-block;
          vertical-align: center;
          &.white {
            @media (max-width: 415px) {
              display: none;
            }
          }
          &.gray {
            display: none;
            @media (max-width: 415px) {
              display: inline-block;
            }
          }
        }
        &_desc {
          display: inline-block;
          vertical-align: center;
          & a {
            color: white;
            &:hover {
              opacity: 1;
              font-weight: bold;
              color: #fff;
            }
          }
        }
        @media (max-width: 1124px) {
          display: none;
        }
      }
      @media (max-width: 1124px) {
        text-align: center;
      }
      @media (max-width: 415px) {
        z-index: 0;
        color: #5d636f;
      }
    }
    @media (max-width: 1124px) {
      position: relative;
      flex-direction: column;
    }
    @media (max-width: 415px) {
      background-color: #fff;
      padding-bottom: 110px;
    }
  }
  &__form {
    margin-top: 34px;
    &__field {
      margin-bottom: 12px;
      input[type='text'],
      input[type='password'] {
        font-size: 1.2em;
      }
      &.last {
        margin-bottom: 8px;
      }
    }
    &__server-error {
      margin-bottom: 30px;
      margin-top: -9px;
    }
    &__btn {
      display: block !important;
      width: 100%;
      margin-top: 24px;
      box-shadow:
        0 22px 20px rgba(52, 52, 52, 0.104),
        0 7px 20px rgba(57, 171, 73, 0.3);
      font-weight: 600 !important;
      height: 64px;
      font-size: 1.4em !important;
      @media (max-width: 415px) {
        padding: 15px 12px !important;
        font-size: 14px !important;
        line-height: initial !important;
      }
    }
    .forgot-password {
      color: #205591;
      text-decoration: underline;
      font-size: 12px;
      margin-top: 16px;
      text-align: center;
      cursor: pointer;
      @media (max-width: 415px) {
        text-align: left;
      }
    }
    @media (max-width: 415px) {
      margin-top: 10px;
    }
  }
  &__earth {
    max-width: 100%;
    height: auto;
    &-wrap {
      display: inline-block;
      width: 1119px;
      height: 1119px;
      position: absolute;
      bottom: -450px;
      right: -443px;
      z-index: -1;
      @media (max-width: 1124px) {
        margin-bottom: 57px;
        bottom: -875px;
        left: 50%;
        transform: translateX(-50%);
      }
      @media (max-width: 768px) {
        width: 760px;
        height: 760px;
        margin-bottom: 163px;
        bottom: -650px;
      }
      @media (max-width: 415px) {
        display: none;
      }
    }
  }

  &__sms-step {
    text-align: center;
    // margin: -64px 0;
    .login-page {
      &__body {
        &__box {
          &__sms {
            margin-top: 5px;
            &-desc {
              margin-top: 24px;
              font-size: 16px;
              color: #858a93;
            }
            &-phone {
              margin-top: 5px;
              &_number {
                display: inline-block;
                vertical-align: middle;
                color: #343c4b;
                font-size: 18px;
                letter-spacing: 1px;
                font-weight: 400;
              }
              &_icon {
                display: inline-block;
                vertical-align: middle;
                margin-left: 11px;
                cursor: pointer;
                width: 22px;
                height: 26px;
              }
            }
          }
        }
        &__info {
          &__title {
            @media (max-width: 415px) {
              margin-bottom: 50px;
            }
          }
        }
      }
      &__form {
        margin-top: 30px;
        &__timer-block {
          margin-top: 16px;
          .text {
            color: #858a93;
          }
          .time {
            color: #343c4b;
          }
        }
        &__btn {
          &.save-btn {
            max-width: 253px;
            margin: 16px auto 0;
          }
          &.cancel-btn {
            max-width: 253px;
            margin: 16px auto 0;
            color: #5d636f !important;
          }
        }
      }
    }
    .resend-sms {
      color: #205591;
      font-size: 16px;
      margin-top: 10px;
      cursor: pointer;
      display: inline-block;
      border-bottom: 1px solid #205591;
      padding-bottom: 0;
    }
    @media (max-width: 1124px) {
      margin: 17px 0;
    }
  }

  &__certificate-step {
    &__error {
      text-align: center;
      &_title {
        font-size: 20px;
      }
      &_desc {
        font-size: 15px;
      }
    }

    .field-mode {
      max-width: 280px;
      margin: 0 auto;
    }
    .field-token {
      display: inline-block;
      width: calc(100% - 40px);
    }
    .update-token {
      cursor: pointer;
      background-color: #ebeff3;
      border-radius: 8px;
      width: 32px;
      height: 32px;
      padding: 8px 9px;
      display: inline-block;
      vertical-align: top;
      margin-left: 8px;
      margin-top: 13px;
    }
    .search-text {
      margin-bottom: 12px;
    }
    .expired-error {
      color: #eb5757;
      font-size: 12px;
      line-height: 17px;
      margin-left: 15px;
      margin-top: 2px;
    }
  }
  @media (max-width: 415px) {
    background-color: #fff;
  }
}

.margin-hr {
  margin-top: 6px;
  margin-bottom: 4px;
}
