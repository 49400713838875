.mb-8 {
  margin-bottom: 8px;
}

.table-counter {
  display: flex;
  align-items: center;
}

.amount-cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
}

@media (max-width: 767px) {
  .wrapper-card .amount-card-wrapper {
    font-size: 16px;
    background: none;
    padding: 0;
  }
}

.payment-amount-wrapper {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 6fr;
  grid-template-areas:
    'counter actions'
    'amount-cards amount-cards';

  &__counter-wrapper {
    grid-area: counter;
  }

  .amount-cards {
    grid-area: amount-cards;
  }

  &__actions-wrapper {
    grid-area: actions;
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'counter'
      'amount-cards'
      'actions';

    &__actions-wrapper {
      display: flex;
      flex-direction: column;

      button {
        margin-right: 0 !important;
      }
    }
  }
}

.amount-card-wrapper {
  background: #f0f1f2;
  border-radius: 8px;
  padding: 5px 8px;

  &__count {
    padding-left: 8px;
    font-size: 12px;
  }
}

.detail-button {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__icon {
    padding-left: 8px;
  }

  &__description {
    color: #31a341;

    @media (min-width: 767px) {
      display: none;
    }
  }
}

.button-wrapper {
  margin-left: 12px;
}

.wrapper-card {
  display: flex;
  align-items: center;
}
