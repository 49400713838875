.edit-field {
  display: flex;
  align-items: center;

  &__enabled {
    &.form-control {
      border: 1px solid #ced4da !important;
    }
  }

  &__icon {
    cursor: pointer;
  }

  .form-control {
    border: none;
    padding: 0 4px;
    border-radius: 4px;
    font-size: 14px;
    color: #5d636f;

    &[readonly] {
      background-color: initial;
      opacity: 1;
    }

    &:focus {
      box-shadow: none;
      border-color: #ced4da;
    }
  }

  &__error {
    position: absolute;
    margin: 0;
    margin-top: 35px;
    padding: 0;
  }
}
