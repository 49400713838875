.row-disabled {
  background: #f5f6f7;
}

.row-currency {
  margin-top: 12px;
  line-height: 50px;
}

.amount-icon {
  margin-top: 12px;
  width: 42px;
}

.amount-icon_disabled {
  opacity: 0.65;
  margin-top: 12px;
  pointer-events: none;
}
