.home-page__title {
  font-size: 32px;
  color: #32465c;
}

.home-page__header {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding: 0 24px;
  }
}

.home-page__droppable {
  user-select: none;
  margin: 0 0 8px 0;
  box-sizing: border-box;
  padding: 10px;
  min-height: 100%;
  width: 100%;

  &_active {
    padding: 8px;
    border: 2px dashed #d7e1ec;
    border-radius: 8px;
  }

  &_over {
    border-color: #4cbc5c;
  }
}

.droppable-wrapper {
  height: 100%;
}
