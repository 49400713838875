.analytics-page {
  display: block;
}

.analytics-filter {
  &__field {
    margin-bottom: 10px;
  }
  &__tabs {
    margin-bottom: 17px;
  }
  &__right {
    text-align: right;
  }
  &__toggle {
    display: inline-block;
    vertical-align: middle;
    .toggle-block__btn_filter {
      .btn-icon {
        margin-right: 0;
      }
    }
  }

  &__row {
    margin-bottom: 10px;
    &_col {
      min-width: max-content;
    }
  }

  &__tabs {
    flex-wrap: wrap-reverse !important;

    .select-tab {
      margin-bottom: 0;
    }
    &_col {
      min-width: max-content;
      margin-bottom: 5px;
    }
  }

  &__buttons {
    justify-content: space-between;
    padding: 0 8px;
  }
}
