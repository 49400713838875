.inp-radio-wrap {
  display: flex;
  margin-top: 12px;

  .inp-radio {
    [type='radio']:checked,
    [type='radio']:not(:checked) {
      display: none;
    }

    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 18px;
      display: inline-flex;
      color: #343c4b;
    }

    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #9098a9;
      border-radius: 100%;
      background: #fff;
    }

    [type='radio']:checked + label:before {
      border: 1px solid #4cbc5c;
    }

    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 10px;
      height: 10px;
      background: #4cbc5c;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }

    [type='radio']:disabled {
      & + label {
        color: #343c4b;
        opacity: 0.4;
      }
    }

    [type='radio']:disabled:not(:checked) {
      & + label {
        &:before {
          background: #eeeeee;
        }
      }
    }

    &:hover {
      [type='radio'] + label:before {
        border-color: #4cbc5c;
      }

      [type='radio']:checked + label:before {
        border-color: #4cbc5c;
      }
    }
  }

  &.inline {
    .inp-radio {
      display: inline-block;
      vertical-align: top;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.block {
    .inp-radio {
      display: block;
    }
  }

  &.flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
