.small-header {
  position: sticky;
  background-color: #f2f7f8;
  width: 100%;
  min-height: 64px;

  .control-wrapper {
    min-height: 64px;
    height: 64px;
    padding: 0 24px;
    background-color: #32465c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 50;
    width: 100%;

    .header-burger {
      cursor: pointer;
    }

    .small-logo-img {
      margin-left: 40px;
    }

    .icons-list {
      display: flex;
      align-items: center;

      &_item {
        &:not(:first-child) {
          margin-left: 12px;
          cursor: pointer;
        }

        .wrap-popover {
          position: relative;

          .notifications-icon {
            cursor: pointer;
          }

          .unread-notifications-mark {
            position: absolute;
            background-color: #e95f5a;
            z-index: 4;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            top: -3px;
            right: -1px;
          }
        }
      }
    }
  }

  .header-info-message {
    position: sticky;
    margin-top: 64px;
    padding: 5px 10px;
    background: #FFF0F0;
  }

}

.logout-button-wrapper {
  margin-top: 12px;
}
