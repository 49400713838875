.update-token {
  cursor: pointer;
  background-color: #ebeff3;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  padding: 8px 9px;
  display: inline-block;
  vertical-align: top;
  margin-top: 15px;
}

.no-connection {
  &__error {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_svg {
      width: 96px;
      height: 96px;
      // background-image: url(../../../../../www/assets/images/icon/error.svg);
      // background-repeat: no-repeat;
      // width: 96px;
      // height: 96px;
    }
    &_title {
      // font-size: 20px;
    }
    &_desc {
      font-size: 15px;
    }
    &_footer {
      display: flex;
      margin-top: 10px;
      > .btn {
        flex: 1;
      }
    }
  }
}

.confirm-sms-modal {
  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #343c4b;
    text-align: center;
  }
  &__desc {
    margin-top: 24px;
    font-size: 16px;
    color: #858a93;
    text-align: center;
  }
  &__phone {
    margin-top: 5px;
    color: #343c4b;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 400;
  }
  &__footer {
    margin-top: 24px;
    &__btn {
      width: 100%;
    }
  }
  &__form {
    margin-top: 30px;
  }
  .expired-sms {
    margin-top: 16px;
    text-align: center;
    color: #858a93;
    &__title {
      display: block;
    }
    &__timer {
      display: block;
      margin-top: 2px;
    }
    &__expired {
      display: block;
      color: #eb5757;
    }
  }
  .resend-sms {
    color: #4cbc5c;
    text-align: center;
    margin-top: 16px;
    cursor: pointer;
    &__title {
      display: inline-block;
      vertical-align: middle;
    }
    &__timer {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
    }
  }
}

.activate-modal {
  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #343c4b;
    text-align: center;
    margin-bottom: 20px;
  }
  &__footer {
    margin-top: 24px;
    &__btn {
      width: 100%;
    }
  }
}

.title-wrap {
  display: flex;
}

.popover-position {
  margin-left: 20px;
  margin-top: 2px;
}

.certificate-status {
  color: #343c4b;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 38px;
}

.certificate-status--active {
  border: 1px solid #94d79d;
}

.certificate-status--inactive {
  border: 1px solid #eb5757;
}

.revoke-modal-error {
  margin-bottom: 16px;
}

.expired-error {
  color: #eb5757;
  font-size: 12px;
  line-height: 17px;
  margin-left: 15px;
  margin-top: 2px;
}

.expired-date {
  color: #eb5757;
}
