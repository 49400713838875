.payroll-filter {
  &__field {
    margin-bottom: 10px;
  }
  &__tabs {
    margin-bottom: 24px;
  }
  &__right {
    text-align: right;
  }
  &__toggle {
    display: inline-block;
    vertical-align: middle;
  }
  &.tablet,
  &.mobile {
    .mocked-grid-filter {
      &__row {
        display: block;
      }
      &__left {
        width: 100%;
        display: block;
        max-width: 100%;
      }
      &__right {
        width: 100%;
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }
  &.mobile {
    .mocked-grid-filter {
      &__create-btn {
        width: 100%;
      }
      &__search {
        width: calc(100% - 112px);
      }
    }
  }
}
.sub-header__item {
  display: inline-block;
  margin-right: 15px;
  vertical-align: top;
}

.get-commision-col {
  display: flex;
  align-items: flex-end;
  button {
    background: none;
    color: #54667a;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
    svg {
      vertical-align: middle;
      margin-right: 15px;
    }
  }
}

.salary-employees-count {
  display: flex;
  align-items: center;
}

.form-wraper {
  margin: 0;
}

.column-amount-field {
  margin: 0;
  min-height: 63px;
  max-width: 140px;
}
