.login-page {
  &__signature-step {
    &__title {
      font-size: 24px;
      font-weight: 500;
      color: #32465c;
      text-align: center;
      margin-bottom: 32px;
    }

    .signature-form-wrapper {
      padding-bottom: 16px;

      .key-not-found {
        padding: 10px 0;
      }

      .search-token-authorizing-step {
        padding: 0 24px;
      }

      .signature-form__field .textfield-outlined > .autocomplete {
        height: 64px;
        padding-top: 20px;
        padding-bottom: 20px;
      }

      .signature-form__field .textfield-outlined > input {
        height: 64px;
      }

      .cancel-btn-color {
        color: #5d636f !important;
      }
    }
  }
}
