.rmodal {
  position: relative;
  z-index: 1400;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    background-color: rgba(50, 70, 92, 0.6);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 65px;
    @media (max-width: 575px) {
      padding: 0 10px;
    }
  }

  &__content {
    position: relative;
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(92, 106, 128, 0.132);
    border-radius: 8px;
    padding: 32px 34px;
    max-width: 700px;
    margin: 85px auto;
  }

  &__close {
    position: absolute;
    top: -50px;
    right: -75px;
    cursor: pointer;

    &.mobile {
      display: none;
    }

    @media (max-width: 575px) {
      display: none;
      &.mobile {
        display: block;
        top: 5px;
        right: 5px;
      }
    }
  }
}

.confirm-modal {
  &__header-text {
    font-weight: 500;
    text-align: center;
  }

  &__message {
    font-weight: 400;
    text-align: center;
  }

  &__text {
    text-align: center;
    font-size: 16px;
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > div:nth-child(2) {
      margin-left: 35px;
    }
  }
}

.ReactModal {
  &__html {
    &--open {
      overflow-y: hidden;
    }
  }
}

.wrap-no-modal {
  display: block !important;
}
