.template-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: left;
  width: 100%;
  margin-top: 12px;

  &_title {
    margin-left: 5px;
    cursor: pointer;
    width: inherit;
    border-bottom: 1px solid #a8aeb9 !important;
  }

  .autocomplete__value-container {
    min-width: 100px;
  }
}

.template-create {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: left;

  &_label {
    cursor: pointer;
    margin-left: 5px;
    max-width: 150px;

    &:hover {
      text-decoration: underline;
    }
  }
}
