.accounts-page {
  &__title {
    font-size: 32px;
    color: #32465c;
  }

  .progress-col {
    padding: 0;
  }

  .data-table-content__table {
    border-collapse: separate;
    border-spacing: 0 0;
    color: #343c4b;

    tr {
      td {
        border-top: 1px solid white;
        border-bottom: 1px solid white;

        &_isHovered:hover {
          cursor: pointer;
          background-color: rgba(148, 215, 157, 0.1);
        }
      }

      td:first-child {
        border-left: 1px solid white;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 0 24px;
      }

      td:nth-child(2) {
        white-space: nowrap;
        max-width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
        padding-left: 0;
      }

      td:last-child {
        border-right: 1px solid white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }

      td:last-child div {
        float: none;
      }
    }

    th:first-child {
      padding: 0 24px;
    }

    th:nth-child(2) {
      padding-left: 0;
    }
  }
}

.accounts-filter {
  .rc-tabs-tab {
    .accounts-filter__tab {
      display: inline-block;
      margin-bottom: 7px;

      &__badge {
        display: inline-block;
        padding: 1px 10px;
        background: #e6eaf0;
        border-radius: 12px;
        margin-left: 8px;
        color: #858a93;
      }
    }
  }

  .rc-tabs-tab-active {
    .accounts-filter__tab__badge {
      color: #ffffff;
      background: #32465c;
    }
  }

  &__field {
    margin-bottom: 10px;
  }

  &__right {
    text-align: right;
  }

  &__search {
    display: inline-block;
    vertical-align: middle;
    max-width: 600px;
    margin: 5px 16px 5px 0;
    width: calc(100% - 185px);

    .search-input {
      &__input {
        padding: 0 0 0 15px;
        min-height: 32px;
        border-radius: 8px;
      }

      &__icon {
        padding: 4px 8px 7px 9px !important;
        background: #ebeff3;
        padding: 13px;
        margin-top: 0;
        border-radius: 8px;
        right: 1px;

        svg {
          vertical-align: middle;
        }
      }
    }
  }

  &__toggle {
    display: inline-block;
    vertical-align: middle;

    .toggle-block__btn_filter {
      padding: 6px 7px !important;

      .btn-icon {
        margin-right: 0;
      }
    }
  }

  &__left {
    margin-top: 15px;
  }

  &.tablet,
  &.mobile {
    .accounts-filter {
      &__row {
        display: block;
      }

      &__left {
        width: 100%;
        display: block;
        max-width: 100%;
        margin-top: 30px;
      }

      &__right {
        width: 100%;
        display: block;
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }

  &.mobile {
    .accounts-filter {
      &__create-btn {
        width: 100%;
      }

      &__search {
        width: calc(100% - 112px);
      }
    }
  }
}

.account-block {
  &__row {
    margin-top: 15px;

    &.sub-header {
      font-size: 14px;
      line-height: 13px;
      color: #5d636f;
      align-items: baseline;

      .sub-header {
        &__item {
          display: inline-block;
          margin-right: 15px;
          vertical-align: top;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .input-date {
        max-width: 100px;
      }
    }

    .select-template {
      &_icon {
        display: inline-block;
        vertical-align: middle;
      }

      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        min-width: 135px;

        .autocomplete {
          &__placeholder {
            color: #205591;
          }
        }
      }
    }

    .add-template {
      border-top: 1px solid #f3f5f8;
      padding-top: 14px;
      margin-top: 40px;

      &_icon {
        display: inline-block;
        vertical-align: middle;
      }

      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        padding-bottom: 4px;
      }
    }
  }

  &__status {
    color: #5d636f;
    border: 1px solid #b3f8b5;
    border-radius: 8px;
    font-size: 10px;
    padding: 5px 9px;
    display: inline-block;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 18px;
    color: #343c4b;
    text-align: center;
  }

  &__right {
    text-align: right;
    vertical-align: middle;

    &_icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      padding-top: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__form {
    margin: 0;

    &__sub-title {
      font-size: 16px;
      color: #aeb1b7;
      text-align: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &__collapse {
      padding: 20px 0;
      border-top: 1px solid #f3f5f8;
      border-bottom: 1px solid #f3f5f8;
      margin: 40px 0;

      &_wrap {
        display: flex;
      }

      &__title {
        font-size: 16px;
        color: #205591;
        font-weight: 400;
      }

      &__icon {
        margin-left: auto;
        cursor: pointer;
      }
    }

    &__field {
      margin-top: 25px;
    }

    .m-t-32 {
      margin-top: 32px;
    }
  }
}

.account-requisites {
  &__col {
    margin-bottom: 10px;

    &__label {
      color: #343c4b;
    }
  }
}

.account-iban {
  font-weight: 500;

  &__code {
    color: #858a93;
  }

  &__zeroes {
    color: #858a93;
  }
}

.account-amount {
  font-weight: 500;
  text-align: right;
}

.account-name {
  display: flex;

  &__edit {
    padding-right: 5px;
    cursor: pointer;
  }

  &__title {
    font-weight: 500;
  }
}

.account-details {
  &__col {
    margin-bottom: 10px;

    &__label {
      color: #858a93;
    }
  }
}

.account-date {
  display: flex;
  align-items: center;
  justify-content: start;
  line-height: initial;

  &__icon-credit {
    color: #4cbc5c;
    font-size: 18px;
    margin-right: 10px;
  }

  &__icon-debit {
    color: #fd6767;
    font-size: 18px;
    margin-right: 10px;
  }
}

.expanded-row-cell__vall {
  word-wrap: break-word;
}
