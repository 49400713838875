.input-main-wrapper {
  position: relative;
  margin-top: 5px;

  .ReactModal__Content & {
    margin-top: 12px;
  }

  form & {
    margin-top: 12px;
  }

  .tooltip-absolute {
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translateY(-40%);
  }

  .input-wrapper {
    width: 100%;
    position: relative;
    display: inline-block;
    border-radius: 4px;
    color: #343c4b;

    &_outline {
      border: 1px solid #d7e1ec;
    }

    &:hover {
      border-color: #32465c;
      color: #353e4f;
    }

    &_error {
      border: 1px solid #e95f5a !important;
    }

    &_success {
      border: 1px solid #4cbc5c !important;
    }

    &_without-outline {
      border: none !important;
    }

    .input-flex-container {
      display: flex;
      align-items: center;

      .input-inner-wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 0;
        display: inline-block;

        .autocomplete__control {
          height: 48px;
          padding: 0 12px;
        }

        .form-input {
          outline: none;
          overflow: hidden;
          padding: 12px;
          border-radius: 4px;
          border: none;
          display: block;
          width: inherit;
          height: 48px;

          &-textarea {
            height: auto;
            overflow: auto;
          }

          &-textarea-parse {
            color: #717781;
            min-height: 87px;
            height: fit-content;

            .btn-primary {
              &:not(:disabled):not(.disabled):active {
                background-color: #4cbc5c;
                border-color: #4cbc5c;
                box-shadow: none;
              }

              &:hover {
                box-shadow: none;
              }
            }
          }
        }

        .form-input:disabled {
          color: #717781;
        }

        .form-input-large {
          height: 64px;
        }

        .form-input-grey-background {
          background-color: #e2e2e2;
        }

        .form-input:-webkit-autofill + .default-label {
          transform: translate(10px, -12px) !important;
          font-size: 12px !important;
          padding: 3px !important;
          background: #fff !important;
          border-color: #a8aeb9 !important;
          top: 0 !important;
        }

        .form-input:autofill + .default-label {
          transform: translate(10px, -12px) !important;
          font-size: 12px !important;
          padding: 3px !important;
          background: #fff !important;
          border-color: #a8aeb9 !important;
          top: 0 !important;
        }

        .default-label {
          z-index: 7;
          position: absolute;
          left: 0;
          top: 0;
          color: #858a93;
          transition: all 300ms ease;
          transform: translate(16px, 12px);
        }

        .input-large {
          top: 8px;
        }

        .input-focus {
          transform: translate(10px, -12px);
          font-size: 12px;
          padding: 3px;
          background: #fff;
          border-color: #a8aeb9;
        }

        .input-value {
          color: #4cbc5c;
        }

        .input-error {
          color: #e95f5a;
        }
      }
    }
  }
}

.sms-field .input-wrapper .input-parent .form-input {
  padding: 20px;
}

.sms-field .input-wrapper input {
  height: 64px;
  letter-spacing: 6px;
  text-align: center;
}

.form-validate-text {
  font-size: 12px;
  line-height: 17px;
  margin-left: 15px;
  margin-top: 2px;
}

.form-input-error {
  @extend .form-validate-text;
  color: #eb5757;
}

.form-input-warn {
  @extend .form-validate-text;
  color: #ff9800;
}
