.document-details {
  display: flex;
  align-content: flex-start;
  align-items: center;
  justify-content: start;

  &_col {
    display: flex;
    align-items: center;

    .text {
      white-space: nowrap;
    }

    .text-date {
      margin: 0 12px;
      text-align: center;
    }

    &:not(:first-child) {
      margin-left: 16px;
    }

    .edit-field input[id='orderNumber'] {
      max-width: 96px;
    }

    .edit-field input[id='orderNumber']:focus {
      width: inherit;
      text-overflow: clip;
    }

    input {
      width: 120px;
    }

    .input-main-wrapper {
      margin-top: 0;
    }

    .default-label {
      display: none;
    }
  }
}

.template-name {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 0px;
}
