.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
    .DayPicker-Day--outside
  ):hover {
  background: #cdf6d3 !important;
  border-radius: 4px;
}

.DayPicker-Day--highlighted:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background: #cdf6d3 !important;
  border-radius: 4px;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #54667a !important;
  background-color: #cdf6d3 !important;
  border-radius: 4px;

  &:hover {
    background-color: #cdf6d3 !important;
  }
}

.input-date {
  border: none;
  width: 100%;
  height: 50px;
  background-image: url('calend.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;

  &__active {
    cursor: pointer;
  }
}

.date-input {
  position: relative;
  padding-left: 12px;

  &__input {
    padding-right: 24px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__with-clear-button {
    padding-right: 45px !important;
  }

  &__clear {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    //z-index: 1100;
  }

  &__wrap-picker {
    position: relative;
    display: block;
  }

  &__picker {
    font-size: 14px;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: absolute;
    left: 0;
    z-index: 1100;
    max-width: 284px;

    .DayPicker-wrapper {
      padding: 24px;
    }

    &.range-picker {
      width: 550px;
      max-width: 550px;
      display: flex;
      right: 0;
      left: auto;

      .DayPicker {
        &:first-child {
          border-bottom: 1px solid #e9e9eb;
        }

        &-wrapper {
          padding: 10px 15px;
        }
      }

      .range-picker {
        &__item {
          &__title {
            margin-top: 15px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      @media (max-width: 627px) {
        display: block;
        left: 0;
        right: initial;
        width: 265px;
        max-width: 265px;
      }
    }
  }

  .date-picker-header {
    position: relative;

    &__left {
      cursor: pointer;
      font-size: 20px;
      color: #4cbc5c;
      font-weight: 500;
      float: left;

      &__icon {
        position: absolute;
        left: 0;
        top: -5px;
      }
    }

    &__right {
      cursor: pointer;
      font-size: 20px;
      color: #4cbc5c;
      font-weight: 500;
      float: right;

      &__icon {
        position: absolute;
        right: 0;
        top: -5px;
      }
    }
  }

  .DayPicker {
    font-size: 14px;
  }

  .DayPicker-Month {
    margin-top: 0;

    .autocomplete {
      &__option {
        font-weight: 300;
      }

      &__value-container {
        flex: none;
      }
    }
  }

  .DayPicker-Caption {
    padding: 0 15px;
    margin-bottom: 12px;
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    text-transform: capitalize;

    .select {
      &-month {
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
        margin-right: 10px;
      }

      &-year {
        display: inline-block;
        vertical-align: middle;
        font-size: 15px;
      }
    }
  }

  &__select-range {
    font-size: 14px;
    background-color: #fff;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: absolute;
    left: 0;
    z-index: 1100;
    min-width: 150px;

    &__item {
      cursor: pointer;
      padding: 8px 24px;

      &:first-child {
        margin-top: 8px;
        padding-bottom: 15px;
        padding-top: 15px;
      }

      &:last-child {
        margin-bottom: 15px;
      }

      &:hover {
        background-color: #cdf6d3;
      }

      &.selected {
        background-color: #4ca852;
        color: #fff;
      }

      &.range-item {
        border-bottom: 1px solid #e9e9eb;
      }
    }
  }

  .click-outside {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1099;
    position: fixed;
  }
}

.textfield-no-outlined {
  .date-input {
    &__clear {
      right: 19px;
      margin-top: 1px;
    }
  }
}

.wrapper-selectors {
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: space-evenly;
}

.date-month {
  flex-basis: 90px;
}

.date-year {
  flex-basis: 60px;
}

.wrapper {
  position: relative;
  font-size: 14px;

  .menu {
    position: absolute;
    z-index: 1052;
    width: 100%;
    max-height: 110px;
    overflow: auto;
    background-color: white;
    top: 25px;
    text-align: left;

    &-item {
      cursor: pointer;
      font-weight: 400;
      padding: 5px 0 5px 5px;
      transition: background-color 100ms ease;
      font-size: inherit;

      &:hover {
        background-color: #4cbc5c;
      }
    }
  }

  .selected {
    padding-left: 5px;
    cursor: pointer;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .clickArea {
    position: fixed;
    z-index: 1051;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
  }
}
