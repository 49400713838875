.announcements-page {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  &__tabs {
    margin-bottom: 10px;
    .rc-tabs-nav-container {
      font-size: 18px;
    }
  }
  &__no-data {
    text-align: center;
    padding-bottom: 30px;
  }
  &__item {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #e6eaf0;
    &__title {
      font-size: 16px;
    }
    &__date {
      color: #aeb1b7;
      font-weight: 500;
    }
    &__desc {
      margin: 10px 0 0 10px;
      word-wrap: break-word;
    }
    &__footer_btn {
      margin-top: 15px;
      display: flex;
      margin-left: auto;
    }
  }
}

.tabs-padding {
  padding: 0 20px;
}
