.expired-modal {
  text-align: center;
  .title {
    margin-bottom: 30px;
    white-space: pre-wrap;
  }
  .list {
    list-style: none;
    padding: 0;
    font-size: 16px;
    color: #2c2b2e;
  }
}
