.cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  display: flex;

  &_label-hover:hover {
    color: #4cbc5c;
  }
}

.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
}

.cbx span:first-child {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
  flex-shrink: 0;
  margin-top: 2px;
}

.cbx span:first-child svg {
  position: absolute;
  top: 2px;
  left: 1px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
  z-index: 500;
}

.cbx span:first-child:before {
  content: '';
  width: 100%;
  height: 100%;
  background: #4cbc5c;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.cbx span:last-child {
  padding-left: 8px;
}

.cbx:hover span:first-child {
  border-color: #4cbc5c;
}

.inline-wrap {
  display: inline-block;
  position: relative;
  min-width: 5px;
}

.enlargedClickableArea {
  padding: 14px 11px 14px 18px;
  position: absolute;
  top: -27px;
  left: -18px;
  cursor: pointer;
}

.inp-cbx:checked + .cbx span:first-child {
  background: #4cbc5c;
  border-color: #4cbc5c;
}

.inp-cbx:checked + .cbx .checkbox-animated:first-child {
  animation: wave 0.4s ease;
}

.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.inp-cbx:checked + .cbx .checkbox-animated:first-child:before {
  transform: scale(3.5);
  transition: all 0.6s ease;
  opacity: 0;
}

.inp-cbx:disabled + .cbx span {
  opacity: 0.5;
}

@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
