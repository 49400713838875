.product-logo {
  padding: 8px 4px 4px;
  min-width: 35px;
  border-radius: 8px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  opacity: 0.6;
  height: 35px;
}
