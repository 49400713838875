.list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

.export-icon {
  display: inline-block;
  cursor: pointer;
}
