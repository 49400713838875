.row-container {
  align-items: center;
  padding: 9px;
  margin-bottom: 8px;
  border-bottom: 1px solid #32465C29;;
}

.col-container {
  overflow-wrap: break-word;
}
