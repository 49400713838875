.term-expiration-modal-wrapper {
  .expiration-modal-icon {
    text-align: center;
    margin-bottom: 14px;
  }
  .customers-list {
    padding: 0;
    margin-left: 18px;
  }
  .expiration-description {
    margin-top: 14px;
  }

  .expiration-modal-footer {
    display: flex;
    justify-content: space-evenly;
    margin-top: 8px;

    .hide-modal-button-label {
      margin-bottom: 6px;
    }

    .close-button-label {
      width: 147px;
    }
  }
}
