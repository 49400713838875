.header-message {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.footer-message {
  font-size: 12px;
  font-weight: 400;
  color: #5D636F;
  text-align: center;
}

.sub-title {
  white-space: pre-wrap;
}
