.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 25px;
  min-width: 25px;
  background-color: #4cbc5c;
  border-radius: 50px;
  margin: 0 5px;

  &__content {
    color: white;
    line-height: 1;
  }
}
