.upload-file {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &__label {
    position: relative;
    &_file {
      line-height: initial;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow: hidden;
      position: absolute;
    }
    &_icon {
      display: inline-block;
      vertical-align: middle;
      width: 12px;
      height: 18px;
    }
    &_name {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      color: #5d636f;
    }
    label {
      margin: 0;
      cursor: pointer;
    }
  }
  &__collapse {
    .file-field-wrapper {
      display: flex;
      justify-content: space-between;
      .attached-files-info {
        font-size: 15px;
        font-style: italic;
      }
    }
    &_wrap {
      cursor: pointer;
    }
    &_title {
      display: inline-block;
      vertical-align: middle;
      color: #4cbc5c;
      margin-right: 4px;
    }
    &_icon {
      display: inline-block;
      vertical-align: top;
    }
  }
  &__collapse_wide {
    width: 100%;
  }
  &__list {
    &__item {
      margin-top: 10px;
      display: flex;
      &.not-valid {
        color: #eb5757;
      }
      &_icon {
        display: inline-block;
        vertical-align: middle;
        width: 12px;
        height: 16px;
      }
      &_name {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        cursor: pointer;
        &:hover {
          color: #4cbc5c;
        }
      }
      &_close {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}
