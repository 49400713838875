.exporting-document {
  .header {
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .error-import-modal {
      width: 100%;
      white-space: pre-wrap;
      display: inline-block;
      vertical-align: middle;
      color: #e95f5a;
      text-align: center;
    }
  }
}
