.hint-input {
  &__floating {
    transform: translateY(4em);
    z-index: 1100;
  }
}

.date-input {
  &__select-range {
    max-height: 400px !important;
    overflow-y: auto !important;
    margin-top: -17px;
    margin-left: 10px;
  }
}

.click-outside {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1099;
  position: fixed;
}
