.large-header-wrapper {

  .control-wrapper {
    width: 100%;
    height: 90px;
    display: flex;
    flex: 0 0 90px;
    flex-wrap: wrap;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;

    .icons-list {
      display: flex;

      &_item {
        &:not(:first-child) {
          margin-left: 24px;
          cursor: pointer;
        }

        .wrap-popover {
          position: relative;

          .notifications-icon {
            cursor: pointer;
          }

          .unread-notifications-mark {
            position: absolute;
            background-color: #e95f5a;
            z-index: 4;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            top: -3px;
            right: -1px;
          }
        }
      }
    }
  }

  .header-info-message {
    padding: 10px 50px;
    background: #FFF0F0;
  }
}
