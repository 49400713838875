.wrapper-form-info {
  &__title {
    font-size: 24px;
    line-height: 27px;
    color: #343c4b;
    text-align: center;
  }

  &__desc {
    font-size: 16px;
    color: #858a93;
    text-align: center;
  }

  &__form {
    margin-top: 30px;
  }

  &__footer {
    margin-top: 24px;

    &__btn {
      width: 100%;
    }
  }
}
