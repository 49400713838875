// .table-pagination {
//     padding: 0 20px 0 64px;

//     &.tablet, &.mobile {
//       padding-left: 24px;
//       .paging {
//         &__size {
//           display: block;
//           width: 100%;
//           flex: none;
//           max-width: 100%;
//           text-align: center;
//           margin-bottom: 24px;
//         }
//         &__pagination {
//           display: block;
//           width: 100%;
//           flex: none;
//           max-width: 100%;
//           text-align: center;
//           .pagination {
//             display: block;
//           }
//         }
//       }
//     }
//   }

.paginator {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_breakpoint_desktop {
    justify-content: space-between;
    flex-direction: row;
  }
}

.paginator__summary {
  font-size: 14px;
}

.paginator__summary-label {
  color: #858a93;
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
}

.paginator__summary-select {
  display: inline-block;
  vertical-align: middle;
  color: #5d636f;
}

.paginator__pages {
  margin: 0;
  padding: 0;
  justify-content: flex-end;

  li {
    padding: 4px 11px;
    margin: 0 5px;
    display: inline-block;
    box-shadow: 0 2px 4px rgba(68, 73, 80, 0.1);
    border-radius: 2px;
    max-height: 32px;
    //min-width: 32px;
    text-align: center;

    a {
      background: #fff;
      color: #5d636f;
      font-size: 16px;
    }

    &:hover,
    &.active {
      background: #4ca852;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      a {
        background: #4ca852;
        color: #fff;
      }
    }

    &.disabled {
      &:hover {
        cursor: default;
      }
      &.page-arrow {
        background: #fff;
        opacity: 0.6;

        a {
          background: #fff;
          color: #5d636f;
        }
      }
    }
    &.dots {
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      cursor: default;
      &:hover {
        background: none;
        a {
          background: none;
          color: #5d636f;
        }
      }
    }
  }
}
