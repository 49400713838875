.link-button {
  background-color: transparent;
  border: none;
  color: #5d636f;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    color: #007bff;
  }
  &:disabled {
    color: lightgrey;
    pointer-events: none;
  }
}

.link-button__icon {
  display: flex;
  align-items: center;
  &:first-child {
    margin-right: 5px;
  }

  &:last-child {
    margin-left: 5px;
  }
}

.bs-button {
  &.btn:disabled {
    opacity: 0.65;
    pointer-events: none;
  }
}
