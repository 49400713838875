.bs-button {
  &.btn {
    border-radius: 8px;
    background-color: #4cbc5c;
    border-color: #4cbc5c;
    box-shadow: none;
    &.disabled {
      opacity: 0.4;
    }
    &:disabled {
      opacity: 0.4;
    }
    &.btn-primary {
      box-shadow: (0px 1px 1px rgba(51, 56, 63, 0.16));
      &:hover {
        opacity: initial;
      }
      &:focus,
      &.focus,
      &:active,
      &.active,
      &:visited {
        background-color: #4ca852;
        border-color: #4ca852;
        box-shadow: none;
      }
      &.disabled {
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        &:visited {
          opacity: 0.4;
          box-shadow: none;
          background-color: #4cbc5c;
          border-color: #4cbc5c;
        }
      }
    }
    &.btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      background-color: #4ca852;
      border-color: #4ca852;
      box-shadow: none;
    }
    &.btn-secondary {
      box-shadow: (0px 1px 1px rgba(51, 56, 63, 0.16));
      background-color: #ebeff3;
      border-color: #ebeff3;
      color: #32465c;
      &:hover {
        opacity: initial;
      }
      &:focus,
      &.focus,
      &:active,
      &.active,
      &:visited {
        background-color: #d7e1ec;
        border-color: #d7e1ec;
        box-shadow: none;
      }
      &.disabled {
        &:hover,
        &:focus,
        &.focus,
        &:active,
        &.active,
        &:visited {
          opacity: 0.4;
          box-shadow: none;
          background-color: #f3f5f8;
          border-color: #f3f5f8;
        }
      }
    }
    &.btn-secondary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle {
      background-color: #d7e1ec;
      border-color: #d7e1ec;
      box-shadow: none;
      color: #5d636f;
    }
    &-lg {
      font-size: 16px;
      padding: 15px 12px;
      &.btn-primary {
        &:hover {
          box-shadow: (0px 4px 8px rgba(49, 163, 65, 0.24));
        }
      }
      &.btn-secondary {
        &:hover {
          box-shadow: (0px 4px 8px rgba(50, 70, 92, 0.16));
        }
      }
    }
    &-md {
      padding: 15px 12px;
      font-size: 16px;
      line-height: 1;
      &.btn-primary {
        &:hover {
          box-shadow: (0px 4px 8px rgba(49, 163, 65, 0.24));
        }
      }
      &.btn-secondary {
        &:hover {
          box-shadow: (0px 4px 8px rgba(50, 70, 92, 0.16));
        }
      }
    }
    &-sm {
      font-size: 14px;
      padding: 7px 12px;
      line-height: initial;
      &.btn-primary {
        &:hover {
          box-shadow: (0px 4px 8px rgba(49, 163, 65, 0.24));
        }
      }
      &.btn-secondary {
        &:hover {
          box-shadow: (0px 4px 8px rgba(50, 70, 92, 0.16));
        }
      }
    }
  }
  &-dropdown:after {
    display: inline-block;
    margin-left: 8px;
    content: ' ';
    border-width: 0 0 2px 2px;
    border-style: solid;
    transform: rotate(-45deg) translateY(-5px);
    width: 8px;
    height: 8px;
  }
  &.btn-primary {
    &.bs-button-dropdown:after {
      border-color: white;
    }
  }
  &.btn-secondary {
    &.bs-button-dropdown:after {
      border-color: black;
    }
  }

  &__label {
    svg {
      vertical-align: middle;
    }

    :nth-child(n) {
      margin-right: 8px;
    }
    :last-child {
      margin-right: 0;
    }
  }
}
