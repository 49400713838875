.list-actions .select-tab {
  margin-bottom: 0;
}
.select-tab {
  border: none;
  margin-bottom: 20px;
  .rc-tabs-bar {
    border: none;
  }
  .rc-tabs {
    &-tab {
      &:hover {
        color: #343c4b;
      }
      padding: 2px 0;
      color: #858a93;
      &-active {
        color: #343c4b;
        &:hover {
          color: #343c4b;
        }
      }
    }
    &-top {
      &.rc-tabs-tab {
        margin-right: 24px;
      }
    }
    &-right {
      padding-left: 5px;
    }
    &-left {
      padding-right: 5px;
    }
  }
  .rc-tabs-ink-bar {
    background-color: #4cbc5c;
  }
  .rc-tabs-tab-prev,
  .rc-tabs-tab-next {
    line-height: 28px;
  }
}
