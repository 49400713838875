.upload-avatar {
  &-wrap {
    position: relative;
    border-radius: 50%;
  }
  &__avatar-img {
    border-radius: 50%;
    &:hover {
      transform: scale(1.4);
    }
  }
  &__edit-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .file-input {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}
