@mixin mod_isWrapped {
  &_isWrapped {
    white-space: unset;
    @content;
  }
}

.data-table-content {
  margin-bottom: 40px;
  overflow-x: auto;
  position: relative;

  &__table {
    width: 100%;
  }

  &__thead {
    color: #858a93;
    font-size: 14px;
  }

  &__tr {
    &_isSelected,
    &_isSelected:hover {
      background-color: #f3f5f8;
    }

    &_allowHovering:hover td {
      cursor: pointer;
      background-color: rgb(244, 251, 245);
    }
  }

  &__sort-arrow {
    margin-left: 5px;
  }

  &__slash {
    margin-left: 5px;
  }

  &__th {
    padding: 1rem 0.75rem;
    border-bottom: 1px solid #e6eaf0;
    white-space: nowrap;
    background-color: #ffffff;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
      position: sticky;
      right: 0;
    }

    &_type_gear {
      cursor: pointer;
      text-align: right;
    }

    .column-title {
      width: fit-content;
    }

    .sortable-column-title {
      display: flex;
      cursor: pointer;
    }

    .isActive {
      color: #4cbc5c;
      font-weight: 500;
    }
  }

  &__tbody {
    color: #343c4b;
  }

  &__td {
    border-color: #e6eaf0;
    border-bottom: 1px solid #e6eaf0;
    padding: 1rem 0.75rem;
    max-width: 215px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: #ffffff;

    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      position: sticky;
      right: 0;
      padding-right: 24px;
    }

    &_type_more {
      cursor: pointer;
    }
  }
}

.data-table-cell-title {
  white-space: nowrap;

  @include mod_isWrapped();
}

.data-table-cell-subtitle {
  display: block;
  font-size: 12px;
  color: #858a93;
  white-space: nowrap;

  @include mod_isWrapped();
}

.popover {
  .settings-list {
    max-height: 310px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
