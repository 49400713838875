.cards-page {
  &__title {
    font-size: 32px;
    color: #32465c;
  }
  .progress-col {
    padding: 0;
  }
  .data-table-content {
    border-collapse: separate;
    border-spacing: 0 10px;
    color: #343c4b;
    tr {
      td {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
      }
      td:first-child {
        border-left: 1px solid white;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 0 24px;
        width: 20px;
      }
      td:nth-child(2) {
        white-space: nowrap;
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 500;
        padding-left: 0;
      }
      .card-name-edit {
        padding-right: 5px;
        cursor: pointer;
      }
      td:last-child {
        border-right: 1px solid white;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
      td:last-child div {
        float: none;
      }
      .card-name {
        font-weight: 500;
      }
      .card-amount {
        font-weight: 500;
        display: block;
        text-align: right;
      }
      .card-status {
        font-size: 12px;
        padding: 4px 8px;
        display: inline-block;
        border-radius: 38px;
        &.active {
          background: rgba(76, 188, 92, 0.1);
          color: #4ca852;
        }
        &.disabled {
          background: rgba(233, 95, 90, 0.1);
          color: #e95f5a;
        }
      }
    }
    .card-details {
      &__col {
        margin-bottom: 10px;
        &__label {
          color: #858a93;
        }
      }
    }
  }
}

.cards-filter {
  .rc-tabs-tab {
    .cards-filter__tab {
      display: inline-block;
      margin-bottom: 7px;
      &__badge {
        display: inline-block;
        padding: 1px 10px;
        background: #e6eaf0;
        border-radius: 12px;
        margin-left: 8px;
        color: #858a93;
      }
    }
  }
  .rc-tabs-tab-active {
    .cards-filter__tab__badge {
      color: #ffffff;
      background: #32465c;
    }
  }
  &__field {
    margin-bottom: 10px;
  }
  &__right {
    text-align: right;
  }
  &__search {
    display: inline-block;
    vertical-align: middle;
    max-width: 600px;
    margin: 5px 16px 5px 0;
    width: calc(100% - 185px);
    .search-input {
      &__input {
        padding: 0 0 0 15px;
        min-height: 32px;
        border-radius: 8px;
      }
      &__icon {
        padding: 4px 8px 7px 9px !important;
        background: #ebeff3;
        padding: 13px;
        margin-top: 0;
        border-radius: 8px;
        right: 1px;
        svg {
          vertical-align: middle;
        }
      }
    }
  }
  &__toggle {
    display: inline-block;
    vertical-align: middle;
    .toggle-block__btn_filter {
      padding: 6px 7px !important;
      .btn-icon {
        margin-right: 0;
      }
    }
  }
  &__left {
    margin-top: 15px;
  }
  &.tablet,
  &.mobile {
    .cards-filter {
      &__row {
        display: block;
      }
      &__left {
        width: 100%;
        display: block;
        max-width: 100%;
        margin-top: 30px;
      }
      &__right {
        width: 100%;
        display: block;
        max-width: 100%;
        margin-top: 10px;
      }
    }
  }
  &.mobile {
    .cards-filter {
      &__create-btn {
        width: 100%;
      }
      &__search {
        width: calc(100% - 112px);
      }
    }
  }
}

.card-block {
  &__row {
    margin-top: 15px;
    &.sub-header {
      font-size: 14px;
      line-height: 13px;
      color: #5d636f;
      align-items: baseline;
      .sub-header {
        &__item {
          display: inline-block;
          margin-right: 15px;
          vertical-align: top;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .input-date {
        max-width: 100px;
      }
    }
    .select-template {
      &_icon {
        display: inline-block;
        vertical-align: middle;
      }
      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        min-width: 135px;
        .autocomplete {
          &__placeholder {
            color: #205591;
          }
        }
      }
    }
    .add-template {
      border-top: 1px solid #f3f5f8;
      padding-top: 14px;
      margin-top: 40px;
      &_icon {
        display: inline-block;
        vertical-align: middle;
      }
      &_title {
        font-size: 14px;
        line-height: 13px;
        color: #205591;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        padding-bottom: 4px;
      }
    }
  }
  &__status {
    color: #5d636f;
    border: 1px solid #b3f8b5;
    border-radius: 8px;
    font-size: 10px;
    padding: 5px 9px;
    display: inline-block;
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    font-size: 18px;
    color: #343c4b;
    text-align: center;
  }
  &__right {
    text-align: right;
    vertical-align: middle;
    &_icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;
      padding-top: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__form {
    margin: 0;
    &__sub-title {
      font-size: 16px;
      color: #aeb1b7;
      text-align: center;
      margin: 15px 0;
    }
    &__collapse {
      padding: 20px 0;
      border-top: 1px solid #f3f5f8;
      border-bottom: 1px solid #f3f5f8;
      margin: 40px 0;
      &_wrap {
        display: flex;
      }
      &__title {
        font-size: 16px;
        color: #205591;
        font-weight: 400;
      }
      &__icon {
        margin-left: auto;
        cursor: pointer;
      }
    }
    &__field {
      margin-top: 25px;
    }
    .m-t-32 {
      margin-top: 32px;
    }
  }
}
