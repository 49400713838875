$offset: 3px;

.react-switch {
  &-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    &:checked {
      + .react-switch-label {
        background-color: #4ca852;
      }
      + .react-switch-label {
        .react-switch-button {
          left: calc(100% - #{$offset});
          transform: translateX(-100%);
        }
      }
    }
    &--grey {
      &:checked {
        + .react-switch-label {
          background-color: #808080;
        }
      }
    }
  }

  &-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 40px;
    height: 20px;
    background: #808080;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
    margin-bottom: 0;
    &:active {
      .react-switch-button {
        width: 25px;
      }
    }
  }
  &-button {
    content: '';
    position: absolute;
    top: $offset;
    left: $offset;
    width: 14px;
    height: 14px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  &-sub-label {
    cursor: pointer;
    padding-top: 0.5rem;
  }
}

.password-login-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;

  .react-switch-label {
    margin-right: 8px;
  }
}
