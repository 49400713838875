.select-option {
  position: relative;

  .wrp-checkbox {
    position: absolute;
  }

  .wrp-children {
    padding-left: 20px;
  }
}

.autocomplete {
  &__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__value-container {
    cursor: pointer;

    &--has-value {
      .selected-value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .selected-value-children-container {
        width: 0;
        height: 0;
      }

      .input-autocomplete {
        &.no-value {
          .autocomplete__input {
            input {
              width: 2px !important;
            }
          }
        }
      }
    }
  }

  &--is-disabled {
    opacity: 0.7;
    background-color: #f8f8fa !important;
  }

  &__clear-indicator {
    color: hsl(0, 0%, 80%);
    display: flex;
    padding: 0;
    transition: color 150ms;
    box-sizing: border-box;
    cursor: pointer;
  }

  &__menu-list {
    &--is-multi {
      .cbx {
        margin-right: 8px;
        display: inline-block !important;
      }
    }
  }
}

.menu-is-open {
  height: 100vh !important;
}

.sort-wrap {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #E6EAF0;
}

.sort-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 10px;
  cursor: pointer;
  font-weight: 400;

  .isActive {
    color: #4CBC5C;
    font-weight: 500;
  }

  &__icon {
    margin-left: 5px;
  }
}
