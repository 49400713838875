$payment-options-hover: #4cbc5c;

@mixin mod_isWrapped {
  &_isWrapped {
    white-space: unset;
    @content;
  }
}

.data-table {
  width: 100%;

  &_isLoading {
    opacity: 0.5;
    pointer-events: none;
  }
}

.data-table__grid {
  padding-bottom: 24px;
}

.data-table__footer {
  padding: 0 24px 40px;
}

.no-records-td {
  text-align: center;
  padding: 24px 0 40px !important;

}
.table {
  text-align: left;
  thead {
    color: #858a93;
    font-size: 14px;
    th.table-settings-trigger {
      cursor: pointer;
      text-align: right;
      &.mobile {
        display: none;
      }
    }
    th {
      border-bottom: 1px solid #e6eaf0;
      &:first-child {
        padding-left: 24px;
      }
      &:last-child {
        padding-right: 24px;
      }
    }
  }
  tbody {
    color: #343c4b;
    tr {
      &.selected,
      &.selected:hover {
        background-color: #f3f5f8;
      }
      &:hover {
        background-color: rgba(148, 215, 157, 0.1);
      }
      td {
        border-color: #e6eaf0;
        border-bottom: 1px solid #e6eaf0;
        max-width: 215px;
        overflow: hidden;
        text-overflow: ellipsis;
        &:first-child {
          padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }
      td > .sub-name {
        display: block;
        font-size: 12px;
        color: #858a93;
        white-space: nowrap;

        @include mod_isWrapped();
      }
      td > .cell-title {
        white-space: nowrap;

        @include mod_isWrapped();
      }
      td > .order-status {
        display: inline-block;
        border: 1px solid #94d79d;
        border-radius: 38px;
        width: 100%;
        text-align: center;
        padding: 0 10px;
      }
      td.payment-options-trigger {
        cursor: pointer;
      }
    }
  }
  .header-item {
    cursor: pointer;
    font-size: 12px;
    .arrow {
      right: -15px;
      position: absolute;
      visibility: hidden;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      &.active {
        visibility: visible;
      }
    }
    &:hover {
      .arrow {
        visibility: visible;
      }
    }
  }
}

.table_actions {
  font-size: 12px;
  color: #5d636f;
}

.table-row-action-popover,
.table-settings-popover {
  .popover {
    width: 260px;
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 7px rgba(68, 73, 80, 0.1);
    .arrow::before {
      border-left-color: #ffffff;
    }
    ul {
      cursor: pointer;
      list-style: none;
      padding: 0;
      margin: 0;
      > li {
        padding: 10px 0px 5px 10px;
        display: block;
      }
      > li:last-child {
        padding-bottom: 10px;
      }
      > li:hover {
        color: $payment-options-hover;
      }
    }
  }
}

.payment-options-popover,
.table-settings-popover {
  .popover {
    border: 0;
    border-radius: 8px;
    box-shadow: 0px 2px 8px 7px rgba(68, 73, 80, 0.1);
    .arrow::before {
      border-left-color: #ffffff;
    }
    ul {
      cursor: pointer;
      list-style: none;
      padding: 0;
      margin: 0;
      > li {
        width: 230px;
        padding: 10px;
        display: block;
      }
      > li:last-child {
        padding-bottom: 10px;
      }
      > li:hover {
        color: $payment-options-hover;
      }
    }
  }
}

.expanded-row {
  padding: 16px 24px;
}

.expanded-row-cell {
  margin-bottom: 15px;
  &__label {
    color: #858a93;
  }
}

.isActive {
  color: #4cbc5c;
}
