.counter-description {
  margin-top: 8px;

  .description {
    font-weight: 500;
    font-size: 16px;
  }

  .counter-wrapper {
    width: max-content;
    display: inline-block;
  }
}
