.reject-modal {
  width: 100%;

  &__error {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    &_svg {
      width: 96px;
      height: 96px;
    }
  }


  &__buttons {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button {
    width: 50%;
  }
}
